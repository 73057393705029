import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
}).$mount('#app')


