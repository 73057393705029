<template>
    <v-app light>
    <v-content>
      <section>
        <v-layout
          column
          wrap
          class="my-5"
          align-center
        >
          <v-flex xs12 sm4 class="my-3">
            <div class="text-xs-center">
              <h2 class="display-2 font-weight-black">Pedicure</h2>
              <span class="display-1 font-weight-light">
                Alphen aan den Rijn
              </span>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap align-center>
                <v-flex xs12 md8>
                  <v-card class="outlined transparent">
                    <v-img
                        class="white--text"
                        max-height="175px"
                        src="assets/sky.webp"
                      ></v-img>
                    <v-card-text>
                      <v-spacer></v-spacer>
                      <blockquote class="blockquote font-weight-light">
                      Als u op zoek bent naar een pedicure aan huis bent u aan het juiste adres. Ik ben Angelie Peijnenburg, al ruim 25-jaar ambulant pedicure in Alphen aan den Rijn. Wanneer ik bij u langskom krijgt u de kwaliteit en zorg die u nodig heeft. Ik sta onder andere in het kwaliteitsregister van ProVoet, ook heb ik de aantekening Diabete en Reuma behaalt. 
                      </blockquote>
                      </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="layout justify-center">
                      <blockquote class="blockquote font-weight-light">
                        <v-list class="transparent"><v-icon class="blue--text">done</v-icon>&nbsp;
                          <v-list-item><v-list-item-content>kwaliteitsregister ProVoet</v-list-item-content></v-list-item>
                        </v-list> 
                        <v-list class="transparent">
                          <v-icon class="blue--text">done</v-icon>&nbsp;
                          <v-list-item>  <v-list-item-content>aantekening Diabete & Reuma</v-list-item-content></v-list-item>
                          </v-list> 
                          <v-list class="transparent">
                            <v-icon class="blue--text">done</v-icon>&nbsp;
                          <v-list-item>  <v-list-item-content>ambulant (bij ú thuis)</v-list-item-content></v-list-item>
                          </v-list> 
                          <v-list class="transparent">
                            <v-icon class="blue--text">done</v-icon>&nbsp;
                          <v-list-item>  <v-list-item-content>slechts €32,- per behandeling</v-list-item-content></v-list-item>
                        </v-list>
                      </blockquote>
                    </v-card-text>
                      <v-img 
                          src="assets/logo-provoet.webp"
                          height="85"
                          contain
                          position="center"
                        ></v-img>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>
      <v-flex class="my-5">
            <div class="text-xs-center">
              <span class="display-2 font-weight-thin">
                Bent u op zoek naar een Ambulant Pedicure?
              </span>
            </div>
          </v-flex>
      <section>
        <v-parallax src="assets/pedicure.webp" height="380">
        <v-layout column align-center justify-center>
          <v-btn
              class="blue lighten-2 mt-5"
              dark
              large
              href="mailto:info@pedicurealphenaandenrijn.nl"
            >
              Neem contact op &nbsp;
              <v-icon class="white--text">email</v-icon>
            </v-btn>&nbsp;
            <div class="headline mb-3 text-xs-center font-weight-black"> Of maak een afspraak via</div>
            <div class="headline white--text mb-3 text-xs-center font-weight-black">
              <v-icon class="blue--text">phone</v-icon>
              0172-436118</div>
                      <div class="headline white--text mb-3 text-xs-center font-weight-black"><v-icon class="blue--text">smartphone</v-icon>06-39213111
                    </div>
          </v-layout>
        </v-parallax>
      </section>
      <section>
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-5">
            <v-flex xs12 sm4>
              <v-card class="elevation-0 transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Wat u kunt verwachten</div>
                </v-card-title>
                <v-card-text>
                  <blockquote class="blockquote font-weight-light">
                  Met al ruim 25-jaar ervaring kunt u er zeker van zijn dat uw voeten in goede handen zijn. Op afspraak kan ik overal in Alphen aan den Rijn bij ú langskomen. Voor meer informatie kunt u contact met mij opnemen. 
                  </blockquote>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm4 offset-sm1>
              <v-card class="outlined transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Neem gerust contact op</div>
                </v-card-title>
                <v-card-text>
                  <blockquote class="blockquote font-weight-light">
                  U kunt mij per e-mail én per telefoon bereiken. Wanneer u overdag belt is de kans groot dat ik aan het werk ben, als u uw gegevens inspreekt neem ik zo snel mogelijk contact met u op.
                  </blockquote>
                </v-card-text>
                <v-list class="transparent">
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon class="blue--text text--lighten-2">phone</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title class="blue--text">0172-436118</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon class="blue--text text--lighten-2">smartphone</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title class="blue--text">06-39213111</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon class="blue--text text--lighten-2">place</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title class="blue--text">Alphen aan den Rijn</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon class="blue--text text--lighten-2">email</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title class="blue--text"><a href="mailto:info@pedicurealphenaandenrijn.nl">info@pedicurealphenaandenrijn.nl</a></v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
      <v-spacer></v-spacer>
      <v-footer mt-2 class="blue darken-2">
        <v-layout row wrap align-center>
          <v-flex xs12>
            <div class="white--text ml-3">
              <v-icon center class="red--text">favorite</v-icon>
            </div>
          </v-flex>
        </v-layout>
      </v-footer>
    </v-content>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      //
    }
  }
}
</script>